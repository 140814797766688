@primary-color: #22ba7f; // primary color for all components
// @link-color: #0000; // link color
// @success-color: #0000; // success state color
// @warning-color: #0000; // warning state color
// @error-color: #0000; // error state color
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

.ant-layout {
  background: #ffffff;
}

.ant-card-bordered {
  border: 3px solid #f0f0f0;
}

.ant-avatar {
  background: #22ba7f;
}

* {
  font-family: 'Poppins', sans-serif;
}

.ant-btn {
  border-radius: 8px;
}


.ant-input,
.ant-input-password,
.ant-input-number {
  border-radius: 8px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-input-group-wrapper .ant-input-group .ant-input-group-addon:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ant-input-group-wrapper .ant-input-group .ant-input-group-addon:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-picker {
  border-radius: 8px;
}

.ant-select .ant-select-selector {
  border-radius: 8px !important;
}


.form-item-no-title>.ant-form-item-label>label::after {
  display: none;
}


.row-dragging {
  z-index: 100000000;
  display: block;
}