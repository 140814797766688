
.dashboard-tile.ant-card {
  border-radius: 12px;
  transition: transform .2s; 
}
.dashboard-tile.ant-card:hover {
  transform: scale(1.05);  
  cursor: pointer;
}
.dashboard-tile .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 8%;
  padding: 1% 2%;
}
