
.profile .username{
    border: none;
}
.profile .username input{
    font-weight: bold;
    color: #1824b3cf;
}
.profile .username .ant-input-suffix{
    cursor: pointer;
}

.profile .no-style-input{
    border: none;
    font-weight: bold;
}

.profile .no-style-input:active,
.profile .no-style-input:focus,
.profile .no-style-input:hover{
    border: none;
}

