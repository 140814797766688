.added-locale  {
    flex-wrap: nowrap;
    align-items: center;
    place-content: center space-between;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.added-locale .ant-select {
    margin-right: 2%;
}

.added-locale button {
    margin-left: 2%;
}

