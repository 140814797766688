.edit-survey-module {
    .picked-color {
        display: flex;
        flex-direction: row;

        .picked-color-hex {
            height: 32px;
            min-width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .picked-color-wrap {
            width: 100%;
            display: inline-block;
            height: 32px;
            min-width: 100px;
            border-radius: 5px;
            padding: 2px;
            background-color: #dfdfdf;

            span {
                width: 100%;
                display: inline-block;
                height: 28px;
                border-radius: 5px;
            }
        }
        .picked-color-pick-button {
            margin-left: 5px;
        }
    }

    .picker {
        .sketch-picker {
            position: absolute;
            z-index: 100000000;
        }
        .overlay {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 99999999;
        }
    }
    .ant-btn-icon-only {
        width: 30px;
        height: 30px;
    }
}
