.statistics {
    
}

.moduleDashboardTable th, .moduleDashboardTable tr:first-child, .moduleDashboardTable tr:nth-child(2), .moduleDashboardTable tr:nth-child(5), .moduleDashboardTable tr:nth-child(9), .moduleDashboardTable tr:nth-child(10) {
    font-weight: 600 !important;
}

.moduleDashboardTable tr:nth-child(3) td:first-child, .moduleDashboardTable tr:nth-child(4) td:first-child, .moduleDashboardTable tr:nth-child(6) td:first-child, .moduleDashboardTable tr:nth-child(7) td:first-child, .moduleDashboardTable tr:nth-child(8) td:first-child {
    text-indent: 10px;
}