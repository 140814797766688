.section-header {
    height: unset;
    background: #ffffff !important;
}

.section-header .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.section-header .trigger:hover {
    color: #1890ff;
}
