.display-logic .display-logic-rule {
    padding: 10px;
    background-color: #ededed;
    border-radius: 6px;
    display: flex;
    flex-flow: row wrap;
}

.display-logic .display-logic-rule .items{
    display: inline-block;
}
.display-logic .display-logic-rule .item{
    display: inline-block;
    min-height: 32px;
}
.display-logic .display-logic-rule .item .ant-row {
    margin-bottom: 0px;
}

.display-logic .display-logic-rule .item .ant-form-item-label {
    display: none;
}

.display-logic .display-logic-rule span.item {
    padding: 8px;
}
.display-logic .display-logic-rule div.item {
    padding: 4px 8px;
    min-width: 200px;
}
.display-logic .display-logic-rule div.item > div {
    width: 100%;
}

.display-logic .display-logic-rule .actions{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.display-logic .rule-operator .ant-select {
    min-width: 100px;
}
.display-logic .rule-operator .ant-select .ant-select-selector {
    height: 22px;
}
.display-logic .rule-operator .ant-select .ant-select-selector .ant-select-selection-item{
    line-height: 20px;
}


.display-logic .rule-operator {
    padding: 5px 10px;
    text-align: center;
}

.display-logic .rule-operator .rule-operator-separator {
    position: absolute;
    border: none;
    border-top: 2px dotted rgb(0, 160, 107);
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 95%;
    margin-top: 10px;
    margin-left: 2%;
}

.display-logic .numeric-binary .ant-input-group-addon {
    height: 32px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.display-logic .numeric-binary input[type="number"]{
    display: none;
}




.mathematical-range-closure,
.mathematical-symbol {
    float: right;
    font-size: 20px;
}


.display-logic .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}
.display-logic .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}


