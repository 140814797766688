.ant-layout {
  background: #ffffff;
}
.ant-card-bordered {
  border: 3px solid #f0f0f0;
}
.ant-avatar {
  background: #22ba7f;
}
* {
  font-family: 'Poppins', sans-serif;
}
.ant-btn {
  border-radius: 8px;
}
.ant-input,
.ant-input-password,
.ant-input-number {
  border-radius: 8px;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ant-input-group-wrapper .ant-input-group .ant-input-group-addon:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ant-input-group-wrapper .ant-input-group .ant-input-group-addon:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-picker {
  border-radius: 8px;
}
.ant-select .ant-select-selector {
  border-radius: 8px !important;
}
.form-item-no-title > .ant-form-item-label > label::after {
  display: none;
}
.row-dragging {
  z-index: 100000000;
  display: block;
}
.edit-survey .upload-box {
  border: dashed 2px #22ba7f;
  border-radius: 5px;
  padding: 12px;
}
.edit-survey .upload-box p {
  color: #c7c7c7;
  text-align: center;
  margin: 0;
}
