.switch {
    display: inline-block;
}

.switch .title {
    min-width: 100px;
    margin: 0;
    display: inline-block;
    text-align: left;
}

th.ant-table-cell-fix-right {
    right: unset !important;
}

/* td.ant-table-cell-fix-right {
    width: 50px !important;
    display: block;
} */
